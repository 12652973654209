
import { UserSettings } from '../api/src/models/UserSettings';
import uiMixin from '../mixins/ui';

export default {
  name: 'DataRepair',
  mixins: [
    uiMixin,
  ],
  methods: {
    async handleRepair() {
      const userId = await this.$auth.getUserId();

      // This is to simulate the data priming for a new user, but for existing users.
      // This and the onCreate cloud function are the only places where createdAt can be set.
      const { userSettings } = this.$store.state;

      const result = UserSettings.Model.safeParse({
        ...userSettings,
        id: userId,
        createdAt: userSettings.createdAt || new Date(),
      });

      if (result.error) {
        this.uiNotify('Data update failed', {
          severity: 'warning',
          icon: ['fas', 'triangle-exclamation'],
          logMessage: 'data update failed',
        });

        return;
      }

      if (result.success) {
        delete result.data.id;
        await this.$store.dispatch('userSettings/edit', result.data);
        this.$store.dispatch('logEvent', { name: 'data_repair' });

        this.uiNotify('Data updated!', {
          severity: 'success',
          icon: ['fas', 'face-sunglasses'],
          logMessage: 'data updated',
        });
      }
    },
  },
};
